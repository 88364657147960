var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "Posttype:box-title" }, [
        _vm._v("Active Posts")
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.dialogDelete,
                callback: function($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Are you sure you want delete selected live?")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeDelete }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.runDeleteConfirm }
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              search: _vm.search,
              page: _vm.page,
              pageCount: _vm.numberOfPages,
              headers: _vm.headers,
              items: _vm.histories,
              options: _vm.options,
              "items-per-page": 15,
              loading: _vm.loading
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-center truncate" }, [
                        _vm._v(_vm._s(item.id))
                      ]),
                      _c("td", { staticClass: "text-center truncate" }, [
                        _vm._v(_vm._s(item.ana_team))
                      ]),
                      _c("td", { staticClass: "text-center truncate" }, [
                        _vm._v(_vm._s(item.match_descr))
                      ]),
                      _c("td", { staticClass: "text-center truncate" }, [
                        _vm._v(_vm._s(item.match_datetime))
                      ]),
                      _c("td", { staticClass: "text-center truncate" }, [
                        _vm._v(_vm._s(item.home_team))
                      ]),
                      _c("td", { staticClass: "text-center truncate" }, [
                        _vm._v(_vm._s(item.away_team))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.handleClick(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-open-in-app ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.runDelete(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-delete-outline ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }