<template>
  <div class="">
    <div class="Posttype:box-title">Active Posts</div>
    <v-card>
      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want delete selected live?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="runDeleteConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :search="search"
        :page="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="histories"
        :options.sync="options"
        :items-per-page="15"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center truncate">{{ item.id }}</td>
            <td class="text-center truncate">{{ item.ana_team }}</td>
            <td class="text-center truncate">{{ item.match_descr }}</td>
            <td class="text-center truncate">{{ item.match_datetime }}</td>
            <td class="text-center truncate">{{ item.home_team }}</td>
            <td class="text-center truncate">{{ item.away_team }}</td>
            <td class="text-center">
              <v-icon class="mr-2" @click="handleClick(item)">
                mdi-open-in-app
              </v-icon>
              <v-icon class="mr-2" @click="runDelete(item)">
                mdi-delete-outline
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
export default {
  name: "HistoryDatatable",
  data() {
    return {
      search: "",
      page: 1,
      dialogDelete: false,
      totalPassengers: 0,
      numberOfPages: 0,
      histories: [],
      loading: true,
      options: {},
      headers: [
        { text: "Id", value: "id", align: "center" },
        { text: "Analysis Team", value: "ana_team", align: "center" },
        { text: "Match", value: "match_descr", align: "center" },
        {
          text: "Date",
          value: "match_datetime",
          align: "center",
          sortable: false,
        },
        { text: "Home Team", value: "home_team", align: "center" },
        { text: "Away Team", value: "away_team", align: "center" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      overlay: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI();
      },
    },
    deep: true,
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  methods: {
    readDataFromAPI() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      axios
        .get(process.env.VUE_APP_API_URL + "/histories4user/" + this.userid)
        .then((response) => {
          this.loading = false;
          this.histories = response.data;
        })
        .catch((err) => {
          console.error("Error response: " + err);
        });
    },
    handleClick(row) {
      console.log("Clicked On History:" + row.id);
      this.overlay = true;
      this.$store.commit("sethistoryid", row.id);
      this.$store.commit("changeidgame", null); // Interrompo eventuali live e condiziono la table
      this.$store.commit("tableMode", "Historical");
      // Route su animazione
      this.$router.push("/table");
      this.overlay = true;
    },
    runDelete(item) {
      this.editedIndex = this.histories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    runDeleteConfirm() {
      // Recupera la lista di tutti i container per i match attivi
      const param = {
        historyId: this.editedItem.id,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/history/delete", param)
        .then((response) => {
          alert("History Deleted");
        })
        .catch((err) => {
          alert(err);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      window.location.reload();
    },
    reloadPage() {
      window.location.reload();
    },
  },
  mounted() {
    this.readDataFromAPI();
  },
};
</script>
<style>
.truncate {
  max-width: 1 0vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
